import { template as template_1b32c1fa6d7b42f18772b341399c9670 } from "@ember/template-compiler";
const FKLabel = template_1b32c1fa6d7b42f18772b341399c9670(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
