import { template as template_8b02bfa2ae2745f9b3408f3e9398892d } from "@ember/template-compiler";
const SidebarSectionMessage = template_8b02bfa2ae2745f9b3408f3e9398892d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
