import { template as template_ddb6708c9be94e7f84434650c8da2d27 } from "@ember/template-compiler";
const EmptyState = template_ddb6708c9be94e7f84434650c8da2d27(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
